<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR  -->
         <v-dialog v-model="dialogArancelAlu" persistent max-width="550px">
            <v-form
               ref="formArancelAlu"
               v-model="validArancelAlu"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="carnet"
                                 v-mask="'####-AA-###'"
                                 :rules="carnetRules"
                                 color="primary"
                                 label="Carnet"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-autocomplete
                                 v-model="selectArancel"
                                 :items="cmbArancel"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="codigo"
                                 label="Arancel"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              >
                                 <template #item="{ item }">
                                    <v-list-item-content style="width: 0px">
                                       <v-list-item-title
                                          class="wrap-list-title"
                                          v-text="item.nombre"
                                       ></v-list-item-title>
                                       <v-list-item-subtitle
                                          v-text="'Monto ' + item.precio"
                                       ></v-list-item-subtitle>
                                    </v-list-item-content>
                                 </template>
                              </v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <CustomDatePicker
                                 :date="fechaInicio"
                                 label="Fecha Inicio"
                                 :min="toISOLocal(new Date()).substr(0, 10)"
                                 :max="fechaFin"
                                 @updateFecha="fechaInicio = $event"
                              />
                           </v-flex>
                           <v-flex xs12 sm6>
                              <CustomDatePicker
                                 :min="fechaInicio"
                                 :date="fechaFin"
                                 label="Fecha Fin"
                                 @updateFecha="fechaFin = $event"
                              />
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validArancelAlu"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR ArancelesAlu -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover arancel por alumno</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro el arancel por alumno?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">Carnet:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ carnet }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">Arancel:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ selectArancel }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Fecha Inicio:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ fechaInicio }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Fecha Fin:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ fechaFin }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="removerArancelAlu"
                        >Si, eliminar</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Arancel Alumno</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-btn
                  text
                  icon
                  color="white darken-3"
                  @click="nuevoArancelesAlu()"
               >
                  <v-icon>fas fa-plus</v-icon>
               </v-btn>
            </v-toolbar>

            <!-- DATATABLE arancelesAlu -->
            <v-data-table
               :headers="headers"
               :items="arancelesAlu"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Eliminar</span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="arancelesAlu.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import CustomDatePicker from './custom/CustomDatePicker'
import formatters from '../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'ArancelesAlu',
   directives: {
      mask
   },
   components: {
      CustomDatePicker
   },
   mixins: [formatters],

   data: (vm) => ({
      // Variables
      dialogArancelAlu: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Carnet', value: 'carnet', class: 'titulo' },
         { text: 'Arancel', value: 'arancel', class: 'titulo' },
         { text: 'Código', value: 'arancelCodigo', class: 'titulo' },
         { text: 'Fecha Inicial', value: 'fechaInicio', class: 'titulo' },
         { text: 'Fecha Fin', value: 'fechaFin', class: 'titulo' },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      arancelesAlu: [],

      validArancelAlu: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades ArancelesAlu
      alumnoArancelPeriodoId: '',
      carnet: '',
      selectArancel: '',
      cmbArancel: [],
      fechaFin: vm.toISOLocal(new Date()).substr(0, 10),
      fechaInicio: vm.toISOLocal(new Date()).substr(0, 10),

      // Reglas de validacion de formularios
      carnetRules: [(v) => !!v || 'Ingrese un carnet'],
      periodoLectivoRules: [(v) => !!v || 'Ingrese un selectArancel']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nuevo Arancel Personalizado'
            : 'Editar Arancel Personalizado'
      }
   },
   created() {
      //Validando acceso al componente

      if (this.permController(6, 'ver')) {
         this.listarArancelAlu()
         this.listarComboBoxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarArancelAlu() {
         // Obteniendo arancelesAlu
         axios
            .get('api/AlumnoArancelPeriodo')
            .then((response) => {
               this.arancelesAlu = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las arancelesAlu en el datatable se puede resetear
      reset() {
         this.listarArancelAlu()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para ArancelesAlu segun id
         axios
            .get('api/AlumnoArancelPeriodo/' + item.alumnoArancelPeriodoId)
            .then((response) => {
               this.result = response.data
               this.alumnoArancelPeriodoId = this.result.alumnoArancelPeriodoId
               this.carnet = this.result.carnet
               this.selectArancel = this.result.arancelCodigo
               this.fechaFin = this.parseDate(this.result.fechaFin)
               this.fechaInicio = this.parseDate(this.result.fechaInicio)
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogArancelAlu = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unaArancelesAlu
      deleteItem(item) {
         this.dialogEliminar = true
         this.carnet = item.carnet
         this.selectArancel = item.arancelCodigo
         this.alumnoArancelPeriodoId = item.alumnoArancelPeriodoId
         this.fechaFin = item.fechaFin
         this.fechaInicio = item.fechaInicio
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogArancelAlu = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar ArancelesAlu
      save() {
         if (this.$refs.formArancelAlu.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put(
                     'api/AlumnoArancelPeriodo/' + this.alumnoArancelPeriodoId,
                     {
                        arancelCodigo: this.selectArancel,
                        carnet: this.carnet,
                        fechaInicio: this.fechaInicio,
                        fechaFin: this.fechaFin,
                        diasHabiles: 0
                     }
                  )
                  .then(() => {
                     this.listarArancelAlu()
                     this.close()
                     this.snackText = 'Arancel por alumno editado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/AlumnoArancelPeriodo', {
                     arancelCodigo: this.selectArancel,
                     carnet: this.carnet,
                     fechaInicio: this.fechaInicio,
                     fechaFin: this.fechaFin,
                     diasHabiles: 0
                  })
                  .then(() => {
                     this.listarArancelAlu()
                     this.close()
                     this.snackText =
                        'Arancel por alumno agregado exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formArancelAlu.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.carnet = ''
         this.selectArancel = ''
         this.fechaFin = this.toISOLocal(new Date()).substr(0, 10)
         this.fechaInicio = this.toISOLocal(new Date()).substr(0, 10)
      },
      nuevoArancelesAlu() {
         this.dialogArancelAlu = true
      },
      removerArancelAlu() {
         // Enviando metodo delete
         axios
            .delete('api/AlumnoArancelPeriodo/' + this.alumnoArancelPeriodoId)
            .then(() => {
               this.listarArancelAlu()
               this.close()
               this.snackText = 'Arancel por alumno removido exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboBoxes() {
         // Obteniendo sub categorias
         axios
            .get('api/Arancel')
            .then((response) => {
               this.cmbArancel = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
